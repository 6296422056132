:root{
  --mainblue:#2a2a72;
  --mainwhite:#f3f3f3;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  /*font-family: 'Girassol', cursive !important;*/
  font-family: 'Cormorant Garamond', serif !important;
  background: black !important;
  color: var(--mainwhite) !important;
}

.text-title{
  font-size: 20px;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
}

@media (min-width: 768px){
  .navbar {
    
  }

  .navbar .navbar-collapse {
    text-align: center;
  }
}

.modelContainer{
  color: black;
  width: 50%;
  height: 60%;
  position: fixed;
  top: 30%;
  left: 25%;
  right: 30%;
  bottom: 70%;
  background: white;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.modelContainer img{
  width: 150px;
  height: 150px;
  justify-content: center;
  align-self: center;
}

.modelContainer p{
  margin-top: 0;
  text-align: center;
  justify-content: center;
}


